// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
    position: relative;
    /* Needed for absolute positioning of the pseudo-element */
    background-color: black;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 20px;
    font-weight: bold;
    display: inline-block;
    white-space: nowrap;
}

.label::after {
    content: '';
    /* Necessary for display */
    position: absolute;
    right: -5px;
    /* Positioning the triangle to the right of the label */
    top: 25%;
    /* Aligning the triangle to the center of the label */
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    /* Creates the top half of the triangle */
    border-bottom: 10px solid transparent;
    /* Creates the bottom half of the triangle */
    border-left: 10px solid black;
    /* Creates the base of the triangle, colored to match the label */
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Label.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,0DAA0D;IAC1D,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,0BAA0B;IAC1B,kBAAkB;IAClB,WAAW;IACX,uDAAuD;IACvD,QAAQ;IACR,qDAAqD;IACrD,QAAQ;IACR,SAAS;IACT,kCAAkC;IAClC,yCAAyC;IACzC,qCAAqC;IACrC,4CAA4C;IAC5C,6BAA6B;IAC7B,iEAAiE;AACrE","sourcesContent":[".label {\n    position: relative;\n    /* Needed for absolute positioning of the pseudo-element */\n    background-color: black;\n    color: white;\n    font-size: 16px;\n    padding: 10px 20px;\n    border-radius: 20px;\n    font-weight: bold;\n    display: inline-block;\n    white-space: nowrap;\n}\n\n.label::after {\n    content: '';\n    /* Necessary for display */\n    position: absolute;\n    right: -5px;\n    /* Positioning the triangle to the right of the label */\n    top: 25%;\n    /* Aligning the triangle to the center of the label */\n    width: 0;\n    height: 0;\n    border-top: 10px solid transparent;\n    /* Creates the top half of the triangle */\n    border-bottom: 10px solid transparent;\n    /* Creates the bottom half of the triangle */\n    border-left: 10px solid black;\n    /* Creates the base of the triangle, colored to match the label */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
