import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import Tick from '../../assets/Tick.png'; // Replace with your actual image path

const PricingTags = () => {
  const boxContent = [
    {
      heading: 'Choose from best India-based UGC creators',
      description:
        'Find creatives your audience can identify with. Get UGC that targets your preferred demographic and matches your vision from our massive pool of talented and diverse creators.',
    },
    {
      heading: 'High-quality video production',
      description:
        'Our expert team ensures your videos are produced to the highest standards, engaging your audience and enhancing your brand.',
    },
    {
      heading: 'Seamless ad integration',
      description:
        'We integrate your ads smoothly across platforms to maximize reach and effectiveness, ensuring a cohesive brand presence.',
    },
    {
      heading: 'Comprehensive performance tracking',
      description:
        'Track the performance of your ads with detailed analytics, helping you optimize and refine your strategy for better results.',
    },
  ];

  return (
    <Box className="landingPagePricingTags" mt={20} px={[4, 8, 16]}>
    <Text fontWeight='bold' textAlign={{base:"left",md:'center'}} fontSize={{base:'3xl', md:'5xl'}} mb={8}>
      Hassle-free video ads from <br /> start  to finish
    </Text>
    <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={4}>
      {boxContent.map((content, index) => (
        <Box
          key={index}
          display="flex"
          flexDirection="row"
          alignItems="center"
          p={4}
          bg="white"
          borderRadius={10}
        //   boxShadow="md"
          width={['100%', '43%']} // Full width on mobile, 48% for tablets and larger
          mb={4}
          gap={5}
        >
 <Box height="100%" pt={2}>
              <Box
                p={2}
                bgGradient="linear(to-br, #CEBDFF, #5928FF)"
                borderRadius={10} // Make it circular
                mb={4}
                width={{ base: 8, md: 10 }}
                height={{ base: 8, md: 10 }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Image src={Tick} boxSize={{ base: 4, md: 6 }} />
              </Box>
            </Box>

                    <Box>
                    <Text textAlign="left" fontWeight="bold" fontSize={['md', 'lg', 'xl']} mb={2}>
                        {content.heading}
                    </Text>
                    <Text fontSize={['sm', 'md']} textAlign="left">
                        {content.description}
                    </Text>
                    </Box>
        </Box>
      ))}
    </Box>
  </Box>
  );
};

export default PricingTags;
