import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useMediaQuery,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Link,
} from "@chakra-ui/react";
import Close from "../../assets/close.png";
import courselleft from "../../assets/courselleft.png";
import courselright from "../../assets/courselright.png";
import rightArrow from "../../assets/rightArrow.png";
import play from "../../assets/Play.png";
import thumb1 from "../../assets/thumb1.png";
import thumb2 from "../../assets/thumb2.png";
import thumb3 from "../../assets/thumb3.png";
import thumb4 from "../../assets/thumb4.png";
import thumb5 from "../../assets/thumb5.png";
import thumb6 from "../../assets/thumb6.png";
import thumb7 from "../../assets/thumb7.png";
import thumb8 from "../../assets/thumb8.png";
import thumb9 from "../../assets/thumb9.jpeg";
import thumb10 from "../../assets/thumb10.png";

const Carousel = (props) => {
  const video1 =
    "https://hoc-website.s3.ap-south-1.amazonaws.com/merge_41C13594-F2FC-4257-A0D3-5EA0BB2C79F4.MP4";
  const video2 =
    "https://hoc-website.s3.ap-south-1.amazonaws.com/FINAL_perfume.mp4";
  const video3 =
    "https://hoc-website.s3.ap-south-1.amazonaws.com/FINAL_HOOK2.mp4";
  const video4 = "https://hoc-website.s3.ap-south-1.amazonaws.com/HOOK-A.mp4";
  const video5 = "https://hoc-website.s3.ap-south-1.amazonaws.com/IMG_2555.mp4";
  const video6 =
    "https://hoc-website.s3.ap-south-1.amazonaws.com/barbox+53+sec+version.mp4";
  const video7 =
    "https://hoc-website.s3.ap-south-1.amazonaws.com/kesha+vringhadi+hair+oil.mp4";
  const video8 =
    "https://hoc-website.s3.ap-south-1.amazonaws.com/italian+suese+hook+1.MP4";
  const video9 =
    "https://hoc-website.s3.ap-south-1.amazonaws.com/okra+gel+vertical.mp4";
  const video10 =
    "https://hoc-website.s3.ap-south-1.amazonaws.com/IMG_3417.MP4";
  const videos = [
    video1,
    video2,
    video3,
    video4,
    video5,
    video6,
    video7,
    video8,
    video9,
    video10,
    video1,
    video2,
    video3,
    video4,
    video5,
    video6,
    video7,
    video8,
    video9,
    video10,
  ];
  const thumbnails = [
    thumb1,
    thumb2,
    thumb3,
    thumb4,
    thumb5,
    thumb6,
    thumb7,
    thumb8,
    thumb9,
    thumb10,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    let interval;
    if (!isModalOpen) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [videos.length, isModalOpen]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? videos.length - 1 : prevIndex - 1
    );
  };

  const openModal = (index) => {
    setIsModalOpen(true);
    setCurrentIndex(index);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const videoWidth = isLargerThan768 ? "800px" : "100%";
  const videoHeight = isLargerThan768 ? "600px" : "300px";

  return (
    <Box
      position="relative"
      overflow="visible"
      width={{ base: "900%", md: "500%" }}
    >
      <Box
        className="scrolling-wrapper"
        style={{
          display: "flex",
          overflow: "hidden",
          whiteSpace: "nowrap",
          width: "100%",
          height: videoHeight,
        }}
      >
        <div
          style={{
            display: "flex",
            overflow: "hidden",
            width: "100%",
            gap: "10px",
          }}
        >
          {videos.map((video, index) => (
            <Box
              borderRadius={10}
              key={index}
              position="relative"
              width={videoWidth}
              height={videoHeight}
              backgroundImage={`url(${thumbnails[index % thumbnails.length]})`}
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              overflow={"hidden"}
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
                transition: isModalOpen ? "none" : "transform 0.9s infinite",
                margin: "0",
                padding: "0",
              }}
            >
              {/* <video
                src={video}
                loop
                style={{
                  display: "inline-block",
                  width: "100%",
                  height: "100%",
                  borderRadius: "10%",
                  objectFit: "cover",
                 
                }}
              /> */}
              <Button
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"40px"}
                position="relative"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                backgroundColor="rgba(0, 0, 0, 0.5)"
                color="white"
                border="none"
                padding={{ base: 2.5, md: 7 }}
                borderRadius="full"
                cursor="pointer"
                onClick={() => openModal(index)}
                _hover={{
                  "> *": {
                    color: "#000",
                  },
                  bg: "#fff",
                }}
              >
                <Image
                  display={"flex"}
                  justifySelf={"center"}
                  alignSelf={"center"}
                  position={"absolute"}
                  fontSize="xl"
                  color={"#fff"}
                  src={play}
                  objectFit="contain"
                  style={{
                    transition: "none !important",
                    animation: "none !important",
                  }}
                />

                {/* <Text
                  display={"flex"}
                  justifySelf={"center"}
                  alignSelf={"center"}
                  position={"absolute"}
                  fontSize="xl"
                  color={"#fff"}
                >
                  ▶
                </Text> */}
              </Button>
            </Box>
          ))}
        </div>
      </Box>
      <Flex
        py={10}
        width={"20%"}
        marginLeft={{ base: "-5%", md: "0%" }}
        justify="center"
        mt="2"
        display={"flex"}
        flexDirection={"column"}
      >
        <Box
          width={"fit-content"}
          display={"flex"}
          alignSelf={"center"}
          marginLeft={10}
        >
          <Button
            border={"1px solid #D9D9D9"}
            bg={"#fff"}
            borderRadius={"full"}
            onClick={handlePrev}
            padding={2}
            mr="2"
          >
            <Image src={courselleft} boxSize={6} />
          </Button>
          <Button
            border={"1px solid #D9D9D9"}
            bg={"#fff"}
            padding={2}
            borderRadius={"full"}
            onClick={handleNext}
          >
            <Image src={courselright} boxSize={6} />
          </Button>
        </Box>
        <Box
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          display={"flex"}
          margin={5}
        >
          <Link
            to="https://calendly.com/houseofcontent/website?month=2024-06"
            target="_blank"
          >
            <Button
              alignSelf={"left"}
              width={"200px"}
              borderRadius={"30px"}
              bg={"#4A0EFB"}
              boxShadow="0px 4px 6px rgba(0, 0, 0, 0.4)"
              _hover={{ bg: "#5B30C0" }}
            >
              <Text color={"#FFF"}>Order Now</Text>
            </Button>
          </Link>
        </Box>
      </Flex>
      <Modal isOpen={isModalOpen} onClose={closeModal} size="xs">
        <ModalOverlay />
        <ModalContent p={0} overflow={"hidden"}>
          <ModalBody style={{ margin: 0, padding: 0 }}>
            <video
              src={videos[currentIndex]}
              controls
              controlsList="nodownload"
              width="100%"
              height="100%"
              style={{ outline: "none", marginBottom: 0 }}
              autoPlay={true}
            />
          </ModalBody>
          <Box
            width={"100%"}
            bg={"#4A0EFB"}
            py={5}
            px={3}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={5}
            onClick={() => {
              closeModal();
              props.executeScrollFunction();
            }}
            style={{ cursor: "pointer" }}
          >
            <Text color={"#fff"}>I want a video like this</Text>
            <Box border={"1px solid #fff"} borderRadius={"full"} padding={1}>
              <Image boxSize={4} src={rightArrow} />
            </Box>
          </Box>
          <ModalFooter>
            <Image boxSize={4} src={Close} onClick={closeModal} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Carousel;
