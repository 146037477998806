import { Box, Image, keyframes } from "@chakra-ui/react";
import React from "react";
import img1 from "../../assets/1.png";
import img2 from "../../assets/2.png";
import img3 from "../../assets/3.png";
import img4 from "../../assets/4.png";
import img5 from "../../assets/5.png";
import img6 from "../../assets/6.png";
import img7 from "../../assets/7.png";
import img8 from "../../assets/8.png";
import img9 from "../../assets/9.png";
import img10 from "../../assets/10.png";
import img11 from "../../assets/11.png";

const images = [
  img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11,
  img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11,img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11,
  img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11
];

const scrollAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`;

function ScrollingLogo() {
  return (
    <Box
      position="relative"
      width="100%"
      height="100px"
      overflow="hidden"
      borderRadius={10}
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        width: "10%",
        background: "linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
        zIndex: 1,
      }}
      _after={{
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: "10%",
        background: "linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
        zIndex: 1,
      }}
    >
      <Box
        display="flex"
        animation={`${scrollAnimation} 10s linear infinite`}
        whiteSpace="nowrap"
      >
        {images.map((src, index) => (
          <Image key={index} boxSize="80px" src={src} alt={`Image ${index + 1}`} objectFit="contain" />
        ))}
      </Box>
    </Box>
  );
}

export default ScrollingLogo;
