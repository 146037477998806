import {
  Box,
  Button,
  Image,
  Spacer,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  extendTheme,
} from "@chakra-ui/react";
import React, { useState, useRef, useEffect } from "react";
import PricingImage from "../assets/PricingImage.png";
import charm_tick from "../assets/charm_tick.png";
import { Link } from "react-router-dom";
import { color } from "framer-motion";

const customTheme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "GT-Walsheim-Pro-Regular, sans-serif",
      },
    },
  },
  fonts: {
    heading: "GT-Walsheim-Pro-Regular, sans-serif",
    body: "GT-Walsheim-Pro-Regular, sans-serif",
  },
});

const PricingTags = () => {
  // const pricingTagsRef = useRef(null);
  // const firstCardRef = useRef(null);

  // // Pass the refs to the parent
  // useEffect(() => {
  //   setPricingTagsRef(pricingTagsRef);
  //   setFirstCardRef(firstCardRef);
  // }, [setPricingTagsRef, setFirstCardRef]);

  const [isInitiateModalOpen, setIsInitiateModalOpen] = useState(false);
  const [isScaleModalOpen, setIsScaleModalOpen] = useState(false);
  const [isLaunchModalOpen, setIsLaunchModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");

  // Data for each card
  const cardsData = [
    {
      title: "Initiate",
      description:
        "For first-timer looking to try out ads and test us within their workflow",
      videos: "12",
      videoLabel: "Videos",
      features: [
        "2 Hooks (2 different opening per concept)",
        "6 concepts",
        "2 Creators/Actors",
        "2 free edit requests per video",
        "10 days delivery timeline",
      ],
      Price: "75000",
      OgPrice: "",
      Discount: "",
      DiscountText: "",
      onClick: () => {
        setSelectedCard(0);
      },
    },
    {
      title: "Launch",
      description:
        "For individuals and team working hard to breakout with limited resources",
      videos: "24",
      videoLabel: "Videos",
      features: [
        "12% Discount",
        "Actor/Creator Selection ",
        "2 Hooks (2 different opening per concept)",
        "12 Concepts",
        "4 Creators/Actors",
        "2 free edit requests per video",
        "15 days delivery timeline",
      ],
      Price: "132000",
      OgPrice: "",
      Discount: "12%",
      DiscountText: "discount",
      onClick: () => {
        setSelectedCard(1);
      },
    },
    {
      title: "Scale",
      description:
        "For rising professionals and businesses pursuing new, promising opportunities",
      videos: "40",
      videoLabel: "Videos",
      features: [
        "24% Discount",
        "Actor/Creator Selection",
        "2 Hooks (2 different opening per concept)",
        "20 Concepts",
        "2 Creators/Actors",
        "2 free edit requests per video",
        "25 days delivery timeline",
      ],
      Price: "190000",
      OgPrice: "250000",
      Discount: "24%",
      DiscountText: "discount",
      onClick: () => {
        setSelectedCard(2);
      },
    },
  ];

  const handleShowMoreClick = (title) => {
    setSelectedCard(title);
    if (title === "Initiate") {
      setIsInitiateModalOpen(true);
    } else if (title === "Launch") {
      setIsLaunchModalOpen(true);
    } else if (title === "Scale") {
      setIsScaleModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsInitiateModalOpen(false);
    setIsLaunchModalOpen(false);
    setIsScaleModalOpen(false);
  };

  const btnRef = React.useRef(null);

  return (
    <>
      {cardsData.map((card, index) => (
        <Box
          key={index}
          borderRadius={10}
          width={{ base: "100%", md: "30%" }}
          height={{ base: "auto", md: "800px" }}
          border="solid #F8F8F8 1px"
          my={{ base: 10, md: 0 }}
          mx={{ base: 0, md: 8 }}
          padding={2}
          boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
          display="flex"
          flexDirection="column"
        >
          <Box
            backgroundImage={`url(${PricingImage})`}
            backgroundSize="cover"
            backgroundPosition="center"
            width="100%"
            borderRadius={10}
            padding={6}
          >
            <Text fontSize={["2xl", "2xl", "3xl", "4xl"]} color="#FFF">
              {card.title}
            </Text>
            <Text
              display={{ base: "block", md: "block" }}
              fontSize={["sm", "lg"]}
              color="#FFF"
            >
              {card.description}
            </Text>
          </Box>

          <Box
            padding={5}
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
          >
            <Box>
              {/* <Text>Up to</Text> */}
              <Text
                bgGradient="linear(180deg, #0C1658 0%, #180BFF 99.4%)"
                bgClip="text"
                fontSize={["6xl"]}
                fontWeight="extrabold"
                marginBottom={1}
                mx={4}
                // bg={'red'}
              >
                {card.videos}
              </Text>
            </Box>
            <Box width={"70%"} marginBottom={7}>
              <Text fontWeight={"normal"} fontSize={["lg", "lg", "sm", "sm"]}>
                {card.videoLabel}
              </Text>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap={2} paddingLeft={5}>
            {card.features.map((feature, featureIndex) => (
              <Box key={featureIndex} display="flex" flexDirection="row">
                <Image marginTop={0.5} height={4} width={4} src={charm_tick} />
                <Text fontSize="sm">{feature}</Text>
              </Box>
            ))}
          </Box>

          <Spacer />

          <Box textAlign="right" paddingRight={5}>
            <Button
              onClick={() => handleShowMoreClick(card.title)}
              colorScheme="#fff"
              display={"flex"}
              alignSelf={"flex-start"}
            >
              <Text
                textDecoration={"underline"}
                fontWeight={"semibold"}
                color={"#4A0EFB"}
              >
                More details
              </Text>
            </Button>
          </Box>

          <Box
            margin={5}
            display={"flex"}
            gap={1}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Text as="s" color={"#4A0EFB"}>
              {card.OgPrice}
            </Text>{" "}
            <Text color={"#4A0EFB"}> {card.Discount} </Text>{" "}
            <Text color={"#717171"}>{card.DiscountText}</Text>
          </Box>

          <Box textAlign="center" paddingX={5} paddingBottom={5}>
            <Link
              to="https://calendly.com/houseofcontent/website?month=2024-06"
              target="_blank"
            >
              <Button
                borderRadius={40}
                width="100%"
                bg="#4A0EFB"
                color="#FFF"
                boxShadow="0px 4px 6px rgba(0, 0, 0, 0.4)"
                _hover={{ bg: "#5B30C0" }}
              >
                Order Now
              </Button>
            </Link>
          </Box>

          <Modal
            isOpen={isInitiateModalOpen && selectedCard === "Initiate"}
            onClose={closeModal}
            size="2xl"
            isCentered
            blockScrollOnMount={false}
            scrollBehavior="inside"
          >
            <ModalOverlay backgroundColor="rgba(0,0,0, 0.1)" />
            <ModalContent
              margin={5}
              // height={'500px'} overflowY="auto" sy={{ WebkitOverflowScrolling: 'touch' }}
            >
              <ModalHeader> </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box margin={15}>
                  <Text fontSize={"24px"} fontWeight="semibold">
                    What is a Initiate Package?
                  </Text>
                  <Text color={"#717171"}>
                    Say goodbye to the social media content blues.
                  </Text>
                  <Text margin={5} display={"flex"}>
                    <Text fontWeight="bold" fontSize={"30px"}>
                      {/* ₹75000 */}
                    </Text>
                  </Text>
                </Box>

                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      2 Hooks
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      2 different attention-grabbing 3-10 seconds opening to
                      engage viewers instantly.
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      (6 Concepts X 2 Hooks X 2 Actors/Creators = 12 Videos)
                    </Text>
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      Post-delivery revisions
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      Ask for edits to align the videos perfectly with your
                      needs & expectations!
                    </Text>
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      10 days delivery timeline
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      Once we receive the products, we will deliver the final
                      videos within 10 days.
                    </Text>
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      From Scripting to high-quality Editing, everything is
                      Included
                    </Text>
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      Priority support
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      Lightning-fast & personalized support when you need help
                      and you want it now.
                    </Text>
                  </Text>
                </Box>
                <Link
                  to="https://calendly.com/houseofcontent/website?month=2024-06"
                  target="_blank"
                >
                  <Button
                    bg={"#4A0EFB"}
                    my={10}
                    width={"100%"}
                    borderRadius={40}
                    _hover={{
                      bg: "#fff",
                      border: "1px solid #4A0EFB",
                      color: "#4A0EFB",
                    }}
                    color={"#fff"}
                  >
                    {" "}
                    <Text>Order Now</Text>
                  </Button>
                </Link>
              </ModalBody>
            </ModalContent>
          </Modal>

          <Modal
            isOpen={isLaunchModalOpen && selectedCard === "Launch"}
            onClose={closeModal}
            size="2xl"
            isCentered
            blockScrollOnMount={false}
            scrollBehavior="inside"
          >
            <ModalOverlay backgroundColor="rgba(0,0,0, 0.1)" />
            <ModalContent
              width={"90%"}
              margin={5}
              // height={'500px'} overflowY="auto" sy={{ WebkitOverflowScrolling: 'touch' }}
            >
              <ModalHeader></ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box margin={5}>
                  <Text fontSize={"24px"} fontWeight="semibold">
                    What is a Launch Package?
                  </Text>
                  <Text color={"#717171"}>
                    Say goodbye to the social media content blues.
                  </Text>
                  <Text
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                  >
                    <Text fontWeight="bold" fontSize={"30px"} my={5}>
                      {/* ₹132000  */}
                    </Text>
                    <Text mx={2} as="s" color={"#4a0efb"} my={5}>
                      {/* ₹150000  */}
                    </Text>
                    <Text color={"#4a0efb"} my={5}>
                      12%
                    </Text>
                    <Text mx={2} my={5}>
                      Discount
                    </Text>
                  </Text>
                </Box>

                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      2 Hooks
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      2 different attention-grabbing 3-10 seconds opening to
                      engage viewers instantly.{" "}
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      (12 Concepts X 2 Hooks X 4 Actors/Creators = 24 Videos)
                    </Text>
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      Creators/Actors Selection
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      Choose from a wide range of talented creators and actors
                      to find the perfect fit.
                    </Text>
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      Post-delivery revisions
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      Ask for edits to align the videos perfectly with your
                      needs & expectations!
                    </Text>
                  </Text>
                </Box>
                {/* <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight='semibold'>Post-delivery revisions</Text>
                    <Text fontSize="md" color={'#717171'}>Ask for edits to align the videos perfectly with your needs & expectations!</Text>
                  </Text>
                </Box> */}
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      15 days delivery timeline
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      Once we receive the products, we will deliver the final
                      videos within 10 days
                    </Text>
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      Priority support for a video
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      Lightning-fast & personalised support when you need help
                      and you want it now.
                    </Text>
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      From Scripting to high-quality, Editing everything is
                      Included
                    </Text>
                  </Text>
                </Box>
                <Link
                  to="https://calendly.com/houseofcontent/website?month=2024-06"
                  target="_blank"
                >
                  <Button
                    bg={"#4A0EFB"}
                    my={10}
                    width={"100%"}
                    borderRadius={40}
                    _hover={{
                      bg: "#fff",
                      border: "1px solid #4A0EFB",
                      color: "#4A0EFB",
                    }}
                    color={"#fff"}
                  >
                    {" "}
                    <Text>Order Now</Text>
                  </Button>
                </Link>
              </ModalBody>
            </ModalContent>
          </Modal>
          {/* my={5} */}

          <Modal
            isOpen={isScaleModalOpen && selectedCard === "Scale"}
            onClose={closeModal}
            size="2xl"
            isCentered
            blockScrollOnMount={false}
            scrollBehavior="inside"
            // height={'500px'} overflowY="auto" sy={{ WebkitOverflowScrolling: 'touch' }}
          >
            <ModalOverlay backgroundColor="rgba(0,0,0, 0.1)" />
            <ModalContent margin={8}>
              <ModalHeader></ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box margin={15}>
                  <Text fontWeight="semibold" fontSize={"24px"}>
                    What is a Scale Package?
                  </Text>
                  <Text color={"#717171"}>
                    Say goodbye to the social media content blues.
                  </Text>
                  <Text
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                  >
                    <Text mx={2} as="s" color={"#4a0efb"} my={5}>
                      {/* ₹250000  */}
                    </Text>
                    <Text color={"#4a0efb"} my={5}>
                      24%
                    </Text>
                    <Text mx={2} my={5}>
                      Discount
                    </Text>
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      2 Hooks
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      2 different attention-grabbing 3-10 seconds opening to
                      engage viewers instantly.
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      (20 Concepts X 2 Hooks X 6 Actors/Creators = 40 Videos)
                    </Text>
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      Creators/Actors Selection
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      Choose from a wide range of talented creators and actors
                      to find the perfect fit.
                    </Text>
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      Post-delivery revisions
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      Ask for edits to align the videos perfectly with your
                      needs & expectations!
                    </Text>
                  </Text>
                </Box>
                {/* <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image  marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight='semibold'>Post-delivery revisions</Text>
                    <Text fontSize="md" color={'#717171'}>Ask for edits to align the videos perfectly with your needs & expectations!</Text>
                  </Text>
                </Box> */}
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      15 days delivery timeline
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      Once we receive the products, we will deliver the final
                      videos within 10 days.
                    </Text>
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      Priority support for a video
                    </Text>
                    <Text fontSize="md" color={"#717171"}>
                      Lightning-fast & personalised support when you need help
                      and you want it now.
                    </Text>
                  </Text>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} my={5}>
                  <Image marginTop={1} height={4} width={4} src={charm_tick} />
                  <Text>
                    <Text fontSize="md" fontWeight="semibold">
                      From Scripting to high-quality editing everything is
                      Included
                    </Text>
                    {/* <Text fontSize="md" color={'#717171'}>Lightning-fast & personalised support when you need help and you want it now.</Text> */}
                  </Text>
                </Box>

                <Link
                  to="https://calendly.com/houseofcontent/website?month=2024-06"
                  target="_blank"
                >
                  <Button
                    bg={"#4A0EFB"}
                    my={10}
                    width={"100%"}
                    borderRadius={40}
                    _hover={{
                      bg: "#fff",
                      border: "1px solid #4A0EFB",
                      color: "#4A0EFB",
                    }}
                    color={"#fff"}
                  >
                    {" "}
                    <Text>Order Now</Text>
                  </Button>
                </Link>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      ))}
    </>
  );
};

export default PricingTags;
