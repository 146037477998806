// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/assets/fonts/GT-Walsheim-Pro-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    scroll-behavior: smooth;
}


@font-face {
    font-family: 'GT Walsheim Pro Medium';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;;AAGA;IACI,qCAAqC;IACrC,+DAA8E;IAC9E,mBAAmB;IACnB,kBAAkB;EACpB","sourcesContent":["html {\n    scroll-behavior: smooth;\n}\n\n\n@font-face {\n    font-family: 'GT Walsheim Pro Medium';\n    src: url('../src/assets/fonts/GT-Walsheim-Pro-Regular.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
