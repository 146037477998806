import React, { useRef, useState } from "react";
import "./LandingPage.css";
import Header from "../../components/Header/Header";
import img1 from "../../assets/1.png";
import img2 from "../../assets/2.png";
import img3 from "../../assets/3.png";
import img4 from "../../assets/4.png";
import img5 from "../../assets/5.png";
import img6 from "../../assets/6.png";
import img7 from "../../assets/7.png";
import img8 from "../../assets/8.png";
import img9 from "../../assets/9.png";
import img10 from "../../assets/10.png";
import img11 from "../../assets/11.png";
import video from "../../assets/HERO.mp4";
import video2 from "../../assets/video2.mp4";
import video3 from "../../assets/video3.mp4";
import Label from "../../components/Header/Label";
import PricingTags from "../../components/PricingTags";
import landingPagePart2Image from "../../assets/landingPagePart2Image.png";
import InfoGrid from "../../components/Header/InfoGrid";
import Coursel from "../../components/Header/Coursel";
import FaqComponent from "../../components/FAQs";
import Scrollinglogo from "../../components/Header/scrollinglogo";
import {
  Box,
  ChakraProvider,
  Text,
  VStack,
  HStack,
  Tag,
  AspectRatio,
  useMediaQuery,
  Flex,
  Grid,
  Icon,
  Heading,
  Image,
  Button,
  extendTheme,
} from "@chakra-ui/react";
import Feedback from "../../components/Header/Feedback";
import { Link } from "react-router-dom";

const customTheme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "GT-Walsheim-Pro-Regular, sans-serif",
      },
    },
  },
  fonts: {
    heading: "GT-Walsheim-Pro-Regular, sans-serif",
    body: "GT-Walsheim-Pro-Regular, sans-serif",
  },
});

const LandingPage = () => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  // const [pricingTagsRef, setPricingTagsRef] = useState(null);
  // const [firstCardRef, setFirstCardRef] = useState(null);
  // const [pricingTagsRef, setPricingTagsRef] = useState(null);
  // const pricingTagsRef = useRef(null);
  // const firstCardRef = useRef(null);

  // const scrollToPricingTags = () => {
  //   if (pricingTagsRef.current) {
  //     pricingTagsRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  return (
    <ChakraProvider theme={customTheme}>
      <Box marginTop={{ base: "15%", md: "5%" }}>
        {/* <Header /> */}

        <Box
          width={"100%"}
          alignSelf={"center"}
          className="landingPageContainer"
        >
          <div className="landingPageHeader">
            <Box width={"fit-content"}>
              <Text
                lineHeight="short"
                fontWeight="bold"
                fontSize={{ base: "5xl", md: "5xl" }}
                paddingTop={6}
                textAlign={{ base: "left", md: "left" }}
              >
                Order UGC Videos Now
              </Text>
              <Text
                fontStyle="italic"
                fontWeight="bold"
                fontSize={"2xl"}
                textAlign={{ base: "left", md: "left" }}
              >
                Custom-Made. Fast Turnaround.
              </Text>
              <Text
                lineHeight="short"
                textAlign={{ base: "left", md: "left" }}
                color={"#717171"}
                marginBottom={"5%"}
              >
                Getting a UGC video at HOC is EASY, whether you need for 1
                product or multiple.
              </Text>
              <Text
                lineHeight="short"
                textAlign={{ base: "left", md: "left" }}
                color={"#717171"}
                marginBottom={"5%"}
              >
                Try risk-free now: we’re so confident we’ll find the right video
                creator for your product that we offer a 30-day money-back
                guarantee.
              </Text>
              <Link
                to="https://calendly.com/houseofcontent/website?month=2024-06"
                target="_blank"
              >
                <Box
                  display={"flex"}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                  py={5}
                >
                  <Button
                    borderRadius={40}
                    width={{ base: "100%", md: "30%" }}
                    bg={"#4A0EFB"}
                    color={"#FFF"}
                    boxShadow="0px 4px 6px rgba(0, 0, 0, 0.4)"
                    _hover={{ bg: "#5B30C0" }}
                  >
                    Order Now
                  </Button>
                </Box>
              </Link>
            </Box>
            <Box className="landingPageVideoLink">
              <video
                autoPlay={true}
                width="100%"
                muted
                loop
                type="video/mp4"
                src={video}
              ></video>
            </Box>
          </div>
          <Box
            marginLeft={{ base: "0px", md: "10%" }}
            width={"100%"}
            padding={4}
            display={"flex"}
            flexDirection={{ base: "column", md: "row" }}
            alignItems={"center"}
          >
            <Text
              textAlign={{ base: "center", md: "left" }}
              fontSize={"2xl"}
              fontWeight="bold"
            >
              200+
            </Text>
            <Text
              textAlign={{ base: "center", md: "left" }}
              fontWeight="normal"
              paddingLeft={1}
            >
              {" "}
              brands and agencies advertise with HOC
            </Text>
          </Box>
          {/* <Box className="scrolling-wrapper" py={5} >
          
            <Image boxSize={15} src={img1} alt="Image 1" />
            <Image boxSize={15} src={img2} alt="Image 1" />
            <Image boxSize={15} src={img3} alt="Image 1" />
            <Image boxSize={15} src={img4} alt="Image 1" />
            <Image boxSize={15} src={img5} alt="Image 1" />
            <Image boxSize={15} src={img6} alt="Image 1" />
            <Image boxSize={15} src={img7} alt="Image 1" />
            <Image boxSize={15} src={img8} alt="Image 1" />
            <Image boxSize={15} src={img9} alt="Image 1" />
            <Image boxSize={15} src={img10} alt="Image 1" />
            <Image boxSize={15} src={img11} alt="Image 1" />
            <Image boxSize={15} src={img1} alt="Image 1" />
            <Image boxSize={15} src={img2} alt="Image 1" />
            <Image boxSize={15} src={img3} alt="Image 1" />
            <Image boxSize={15} src={img4} alt="Image 1" />
            <Image boxSize={15} src={img5} alt="Image 1" />
            <Image boxSize={15} src={img6} alt="Image 1" />
            <Image boxSize={15} src={img7} alt="Image 1" />
            <Image boxSize={15} src={img8} alt="Image 1" />
            <Image boxSize={15} src={img9} alt="Image 1" />
            <Image boxSize={15} src={img10} alt="Image 1" />
            <Image boxSize={15} src={img11} alt="Image 1" />
            
            
          </Box> */}
          <Box
            width={{ base: "95%", md: "90%" }}
            overflow={"hidden"}
            height={"fit-content"}
          >
            <Scrollinglogo />
          </Box>
        </Box>

        <Box
          backgroundColor="#fff"
          width="100%"
          padding="5%"
          display="flex"
          flexDirection={{ base: "column-reverse", md: "row" }}
          justifyContent="space-evenly"
        >
          <Box
            borderRadius="38px"
            width={{ base: "100%", md: "25%" }}
            height={{ base: "400px", md: "500px" }}
            padding="20px"
            bgGradient="linear(to-br, #DDDAEE, #FFF, #FFFFFF)"
            mb={{ base: "20px", md: "0" }} // Add margin bottom for spacing on mobile
          >
            <Box
              backgroundImage={`url(${landingPagePart2Image})`}
              backgroundSize="cover"
              backgroundPosition="center"
              width="100%"
              height="100%"
              borderRadius="38px"
            >
              {/* Your content here */}
            </Box>
          </Box>

          <Box
            width={{ base: "100%", md: "30%" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              textAlign="left"
              display={"flex"}
              flexDirection={"column"}
              marginBottom={10}
            >
              <Text fontWeight="500" fontSize={{ base: "3xl", md: "4xl" }}>
                Boost ad performance with Us
              </Text>
              <Text
                fontWeight="600"
                lineHeight="42px"
                fontSize={{ base: "md", md: "lg" }}
                marginTop={{ base: 5, md: 6 }}
              >
                1. Define your vision
              </Text>
              <Text
                fontWeight="600"
                lineHeight="42px"
                fontSize={{ base: "md", md: "lg" }}
              >
                2. Choose your creators
              </Text>
              <Text
                fontWeight="600"
                lineHeight="42px"
                fontSize={{ base: "md", md: "lg" }}
              >
                3. Get your winning ads
              </Text>
              {/* <button className="landingOrderNowButton">Order Now</button> */}
              <Link
                to="https://calendly.com/houseofcontent/website?month=2024-06"
                target="_blank"
              >
                <Button
                  boxShadow="xl"
                  color={"#fff"}
                  bg={"#4A0EFB"}
                  width={{ base: "100%", md: "40%" }}
                  marginTop={{ base: 5, md: 10 }}
                  borderRadius={50}
                  _hover={{ bg: "#5B30C0" }}
                >
                  Order Now
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box px={5} width={"100%"} display={"flex"} flexDirection={"column"}>
          <Box
            width={{ base: "100%", md: "50%" }}
            marginX={10}
            alignSelf={"center"}
          >
            <Text
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight="bold"
              textAlign={{ base: "left", md: "center" }}
              // margin={5}
            >
              91.7% of ads featuring a person’s face attracted more attention
            </Text>
            <Text
              fontSize="xm"
              mt={-2}
              mb={5}
              textAlign={{ base: "left", md: "center" }}
              marginY={5}
            >
              Studies show that ads with faces outperform ads without them.*
              With user generated content, create more effective marketing
              campaigns that grab attention, convey emotion, and build trust.
              <br />
              {/* <em>
                *with UGC, create more effective marketing campaigns that grab
                attention, convey emotion, and build trust.
              </em> */}
            </Text>
          </Box>

          <HStack
            spacing={10}
            display={"flex"}
            justifyContent={"center"}
            flexDirection={isMobile ? "column" : "row"}
            alignItems={isMobile ? "center" : "stretch"} // Center items vertically in column layout
          >
            <Box
              display={isMobile ? "block" : "block"}
              padding={5}
              bgGradient="linear-gradient(289deg, #FFF -0.48%, #FFF 17.44%, #FFF 77.58%, #D9D9D9 106.99%)"
              borderRadius={10}
              width={isMobile ? "80%" : "auto"}
            >
              {" "}
              {/* Adjusted width for mobile */}
              <Box
                position="relative"
                // w={["100%", "300px"]}
                width={{ base: "100%", md: "300px" }}
                h={["auto", "300px"]}
                borderRadius="lg"
                overflow="visible"
                boxShadow="lg"
              >
                <AspectRatio ratio={1 / 1}>
                  <video
                    src={video2}
                    autoplay={true}
                    loop
                    muted
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                </AspectRatio>

                <VStack
                  position="absolute"
                  top={isMobile ? 12 : 12}
                  left={isMobile ? -5 : -10}
                  spacing={2}
                  display={"flex"}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  <Tag colorScheme="blackAlpha" bg={"#000"}>
                    {" "}
                    <Text color={"#fff"}>Less engagement</Text>
                  </Tag>
                  <Tag colorScheme="blackAlpha" bg={"#000"}>
                    {" "}
                    <Text color={"#fff"}>Fewer clicks</Text>
                  </Tag>
                  <Tag colorScheme="blackAlpha" bg={"#000"}>
                    {" "}
                    <Text color={"#fff"}>Fewer conversions</Text>
                  </Tag>
                </VStack>
              </Box>
            </Box>

            <Box
              padding={5}
              bgGradient="linear-gradient(289deg, #D0CAE9 -0.48%, #FEFEFE 17.44%, #FFF 77.58%, #FFF 106.99%)"
              borderRadius={10}
              width={isMobile ? "80%" : "auto"}
            >
              {" "}
              {/* Adjusted width for mobile */}
              <Box
                position="relative"
                w={["100%", "300px"]}
                h={["auto", "300px"]}
                borderRadius="lg"
                overflow="visible"
                boxShadow="lg"
                mt={isMobile ? 5 : 0}
              >
                <AspectRatio ratio={1 / 1}>
                  <video
                    src={video3}
                    autoplay={true}
                    loop
                    muted
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                </AspectRatio>

                <VStack
                  position="absolute"
                  top={isMobile ? 12 : 12}
                  left={isMobile ? -5 : -10}
                  spacing={2}
                  display={"flex"}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  <Tag colorScheme="blackAlpha" bg={"#000"}>
                    {" "}
                    <Text color={"#fff"}>More engagement</Text>
                  </Tag>
                  <Tag colorScheme="blackAlpha" bg={"#000"}>
                    {" "}
                    <Text color={"#fff"}>Higher CTR</Text>
                  </Tag>
                  <Tag colorScheme="blackAlpha" bg={"#000"}>
                    {" "}
                    <Text color={"#fff"}>Greater ROAS</Text>
                  </Tag>
                </VStack>
              </Box>
            </Box>
          </HStack>
        </Box>

        <Box
          ref={myRef}
          className="landingPagePricingTags"
          marginTop={10}
          display={"flex"}
        >
          <Text
            as="b"
            fontSize="4xl"
            textAlign={{ base: "left", md: "center" }}
            paddingLeft={5}
          >
            UGC video packs
            {/* <br /> */}
            to drive results
          </Text>
          <Box
            padding={4}
            id="pricingId"
            width={"100%"}
            display={{ base: "block", md: "flex" }}
            flexDirection={"row"}
            alignItems={"cenrer"}
            justifyContent={"center"}
            paddingX={"7%"}
          >
            {/* <PricingTags /> */}
            <PricingTags />
          </Box>
        </Box>

        <Box>
          <InfoGrid />
        </Box>

        <Box padding={{ base: 1, md: 10 }}>
          <Feedback />
        </Box>

        <Box
          width={"100%"}
          overflow={"hidden"}
          //  bg={'red'}
          position={"relative"}
          _before={{
            content: '""',
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            width: "15%",
            background:
              "linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
            zIndex: 1,
          }}
          _after={{
            content: '""',
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            width: "15%",
            background:
              "linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
            zIndex: 1,
          }}
        >
          {/* <Coursel/> */}
          <Coursel
            executeScrollFunction={() => {
              executeScroll();
            }}
          />
        </Box>

        <Box padding={{ base: 5, md: 10 }} zIndex={9}>
          <FaqComponent />
        </Box>

        {/* <Box  padding={{base:5,md:10}}>
          <Text as='b' fontSize={'xs'}>
          *Study by Gianluigi Guido, Marco Pichierri, Giovanni Pino, and Rajan Nataraajan
          </Text>
          <br/>
          <Text as='b' fontSize={'xs'}>
          
“Effects of face images and face pareidolia on consumers’ responses to print advertising”
          </Text>
        </Box> */}
      </Box>
    </ChakraProvider>
  );
};

export default LandingPage;
