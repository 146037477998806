import React from 'react';
import './Header.css';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, ChakraProvider, Image, Text } from '@chakra-ui/react';
import Logo from '../../assets/logo.png'
import nameLogo from '../../assets/nameLogo.png'


const Header = () => {
  const navigate = useNavigate();
  return (
    // <header className="container-theme header-theme">
    // <ChakraProvider>
    <Box bg={'#fff'} width={'100%'} display={'flex'} flexDirection={'row'}  alignItems={'center'} justifyContent={'space-between'} px={{base: '1%' ,md:'5%'}} py={4} height={'fit-content'}>

      <Image
        onClick={() => {
          navigate('/');
        }}
        loading="lazy"
        src={nameLogo}
        width={'200px'}
         // width={'100px'}
        className="theme-logo"
        alt="Company logo"
        height={'50px'}
       
      />
      {/* <Image src={Logo}/> */}

      
<Link to='https://calendly.com/houseofcontent/website?month=2024-06' target='_blank'>
      <Button  width={{base:'fit-content',md:'fit-content'}} boxShadow='lg' rounded='md' bg={'#fff'} borderRadius={50}  ><Text mx={6} color={'#4a0efb'}>Order Now</Text></Button>
      </Link>
     
      {/* <button className="landingOrderNowButton">Order Now</button> */}
      </Box>
      // </ChakraProvider>
    // </header>
  );
};

export default Header;
