import React from 'react';
import './VideoTab.css';

const DurationTab = (props) => {
  const tabClass =
    props.isSelected === props.title ? 'VideoTab' : 'VideoTabWhite';

  return (
    <div
      className={tabClass}
      onClick={() => {
        props.toggleSelection(props.title);
      }}
    >
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '200px',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // backgroundColor: 'red',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
          >
            <path
              d="M28.1054 19.3427C28.1054 25.907 22.784 31.2285 16.2197 31.2285C9.6554 31.2285 4.33398 25.907 4.33398 19.3427C4.33398 12.7784 9.6554 7.45703 16.2197 7.45703C22.784 7.45703 28.1054 12.7784 28.1054 19.3427Z"
              stroke="black"
              stroke-width="1.82857"
              stroke-linecap="round"
            />
            <path
              d="M18.0482 4.71378H14.3911C13.3812 4.71378 12.5625 3.8951 12.5625 2.88521C12.5625 1.87532 13.3812 1.05664 14.3911 1.05664H18.0482C19.0581 1.05664 19.8768 1.87532 19.8768 2.88521C19.8768 3.8951 19.0581 4.71378 18.0482 4.71378Z"
              stroke="black"
              stroke-width="1.82857"
              stroke-linecap="round"
            />
            <path
              d="M6.1626 8.37105L8.90545 6.54248"
              stroke="black"
              stroke-width="1.82857"
              stroke-linecap="round"
            />
            <path
              d="M26.277 8.37105L23.5342 6.54248"
              stroke="black"
              stroke-width="1.82857"
              stroke-linecap="round"
            />
            <path
              d="M16.2197 19.3423H24.4483C24.4483 14.7978 20.7643 11.1138 16.2197 11.1138V19.3423Z"
              fill="black"
            />
          </svg>
          <span style={{ marginLeft: '20px' }}> {props.title}</span>
        </div>
        {/* <p className="videoTitle">{props.title}</p> */}
        <p className="videoPrice">
          +$99 <span className="eachVideo">/each video</span>
        </p>
      </div>
      <div class="radio-container">
        <input
          // onClick={}
          checked={props.isSelected === props.title}
          type="radio"
          name="example"
        />
      </div>
    </div>
  );
};

export default DurationTab;
