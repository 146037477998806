import { Box, Text, Image, Button } from "@chakra-ui/react";
import React, { useState } from "react";
import Star from "../../assets/star.png";
import leftArrow from "../../assets/leftArrow.png";
import rightArrow from "../../assets/rightArrow.png";
import { Link } from "react-router-dom";

const feedbacks = [
  {
    stars: 5,
    title: "Very best services company.",
    subtitle: "Very best services company.",
    company: "Top Up Deal Pvt. Ltd.",
    date: "April 13",
  },
  {
    stars: 5,
    title: "Very convenient and valuable tool.",
    subtitle:
      "Very convenient and valuable tool. the whole journey was very smooth.",
    company: "Groomed",
    date: "April 4",
  },
  {
    stars: 5,
    title: "Very best services company.",
    subtitle: "Very best services company.",
    company: "Top Up Deal Pvt. Ltd.",
    date: "April 13",
  },
];

const Feedback = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === feedbacks.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? feedbacks.length - 1 : prevIndex - 1));
  };

  return (
    <Box width="100%" position="relative" marginTop={10}>
      <Box width="100%" display="flex" justifyContent="center">
        <Text as="b" fontSize={{ base: '3xl', md: "5xl" }} textAlign={{ base: 'left', md: "center" }}>
          Why clients love us?
        </Text>
      </Box>

      <Box
        bg={"#F6F9FA"}
        borderRadius={15}
        gap={10}
        display={"flex"}
        flexDirection={{ base: "column", md: "row" }}
        marginTop={10}
        padding={{ base: 5, md: 15 }}
      
      >
        <Box
          width={"max-content"}
          display={"flex"}
          flexDirection={"column"}
          gap={5}
          alignSelf={"center"}
          
        >
          <Text textAlign={"center"} fontWeight={'semibold'} fontSize="2xl">
            Great
          </Text>

          <Box display={"flex"} flexDirection={"row"} gap={1}>
            {[...Array(4)].map((_, index) => (
              <Box key={index} padding={1}  borderRadius={10} bg={"#72D210"} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Image src={Star} boxSize={5} mx={{base:0,md:2}} />
              </Box>
            ))}
            <Box padding={1} borderRadius={10} bg={"#DEE2E6"} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Image src={Star} boxSize={5}  mx={{base:0,md:2}} />
            </Box>
          </Box>
          <Box
            alignSelf={"center"}
            display={"flex"}
            flexDirection={"column"}
            gap={1}
          >
            <Text textAlign={'center'}>Based on </Text>
            <Text textDecoration={"underline"}>406 reviews </Text>
          </Box>
        </Box>

        <Box
          className="carousel"
          display={{ base: "flex", md: "flex" }}
          flexDirection={"row"}
          position="relative"
          overflowX="auto"
          css={{
            "&::-webkit-scrollbar": {
              display: "none" /* Hide scrollbar for WebKit (Chrome, Safari, etc.) */
            },
            msOverflowStyle: "none", /* Hide scrollbar for IE and Edge */
            scrollbarWidth: "none", /* Hide scrollbar for Firefox */
            margin: "0 -10px"
          }}
        >
          <Box
            position="absolute"
            left={0}
            top="50%"
            transform="translateY(-50%)"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            zIndex={1}
          >
            <Box
              padding={2}
              border={'1px solid #D9D9D9'}
              width={'fit-content'}
              height={'max-content'}
              borderRadius={'full'}
              bg="white"
              cursor="pointer"
              onClick={handlePrev}
            >
              <Image src={leftArrow} boxSize={6} />
            </Box>
          </Box>

          {feedbacks.map((feedback, index) => (
            <Box
              key={index}
              marginX={{ base: 10, md: 0 }}
              alignSelf="center"
              padding={5}
              px={{base:0,md:20}}
              // bg={'red'}
              borderRadius={10}
              minWidth={{ base: "250px", md: "calc(100% / 3 - 20px)" }}
              display={{ base: index === currentIndex ? "block" : "none", md: "block" }}
            >
              <Box display={"flex"} flexDirection={"row"} gap={0.5}>
                {[...Array(feedback.stars)].map((_, starIndex) => (
                  <Box
                    key={starIndex}
                    padding={2}
                    borderRadius={10}
                    bg={"#00BA7B"}
                  >
                    <Image src={Star} boxSize={4} />
                  </Box>
                ))}
              </Box>

              <Box marginTop={5} display={"flex"} flexDirection={"column"}>
                <Text as="b">{feedback.title}</Text>
                <Text as="b" color={"#787878"}>
                  {feedback.subtitle}
                </Text>
              </Box>

              <Box marginTop={10}>
                <Text as="b">{feedback.company}</Text>
                <Text>{feedback.date}</Text>
              </Box>
            </Box>
          ))}

          <Box
            position="absolute"
            right={0}
            top="50%"
            transform="translateY(-50%)"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            zIndex={1}
          >
            <Box
              padding={2}
              border={'1px solid #D9D9D9'}
              width={'fit-content'}
              height={'max-content'}
              borderRadius={'full'}
              bg="white"
              cursor="pointer"
              onClick={handleNext}
            >
              <Image src={rightArrow} boxSize={6} />
            </Box>
          </Box>


        </Box>
      </Box>

      <Box
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        marginTop={50}
      >
      <Link to='https://calendly.com/houseofcontent/website?month=2024-06' target="_blank">
        <Button
          alignSelf={"center"}
          width={"200px"}
          borderRadius={"30px"}
          bg={"#4A0EFB"}
          boxShadow="0px 4px 6px rgba(0, 0, 0, 0.4)"
          _hover={{ bg: "#5B30C0" }}
        >
          <Text color={"#FFF"}>Order Now</Text>
        </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Feedback;
