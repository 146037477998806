// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-container {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 20px;
    z-index: 9;
}

.faq-item {
    border-top: 1px solid #eee;
}

/* Remove border-top from the first FAQ item */
.faq-item:first-of-type {
    border-top: none;
}

.faq-question {
    font-size: 16px;
    /* padding: 5px; */
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-question:hover {
    /* background-color: #f1f1f1; */
}

.faq-answer {
    font-size: 16px;
    display: none;
    padding: 15px;
    /* background-color: #fafafa; */
}
`, "",{"version":3,"sources":["webpack://./src/components/FAQ.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,sBAAsB;IACtB,mBAAmB;IACnB,oCAAoC;IACpC,aAAa;IACb,UAAU;AACd;;AAEA;IACI,0BAA0B;AAC9B;;AAEA,8CAA8C;AAC9C;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,eAAe;IACf,aAAa;IACb,aAAa;IACb,+BAA+B;AACnC","sourcesContent":[".faq-container {\n    width: 100%;\n    background-color: #fff;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0,0,0,0.1);\n    padding: 20px;\n    z-index: 9;\n}\n\n.faq-item {\n    border-top: 1px solid #eee;\n}\n\n/* Remove border-top from the first FAQ item */\n.faq-item:first-of-type {\n    border-top: none;\n}\n\n.faq-question {\n    font-size: 16px;\n    /* padding: 5px; */\n    cursor: pointer;\n    font-weight: bold;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.faq-question:hover {\n    /* background-color: #f1f1f1; */\n}\n\n.faq-answer {\n    font-size: 16px;\n    display: none;\n    padding: 15px;\n    /* background-color: #fafafa; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
