import React from 'react';
import './Login.css';
import Header from '../../components/Header/Header';
import loginWithGoogle from '../../assets/loginWithGoogle.png';
import login from '../../assets/login2.png';
import downloadApp from '../../assets/downloadApp.png';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Header />
      <div className="loginDiv">
        <div className="loginDivContent">
          <p className="loginTitle">For businesses</p>
          <p className="loginSubtitle">Order & manage your videos</p>
          <div className="loginDivButtons">
            <div className="loginDivButtonsHead">
              <p className="loginDivButtonsHeadTitle">Log In</p>
            </div>
            <img
              style={{ marginTop: '20px', marginLeft: '20px' }}
              src={loginWithGoogle}
              width="220px"
              alt=""
            />
            <p className="orContent">or</p>
            <img width="260px" src={login} alt="" />
            <button
              onClick={() => {
                navigate('/Campaigns');
              }}
              className="loginButton"
            >
              Log In
            </button>
          </div>
        </div>
        <div className="loginDownloadContent">
          <img src={downloadApp} width="100%" alt="" />
        </div>
      </div>
      <p className="loginFooter">
        By using our service, you agree to
        <span className="loginTerms">
          Terms of Service, Privacy Policy and Refund Policy.
        </span>
      </p>
    </div>
  );
};
