import React, { useState } from "react";
import "./VideoTab.css";

const VideoTab = (props) => {
  const tabClass =
    props.isSelected === props.title ? "VideoTab" : "VideoTabWhite";

  return (
    <div
      className={tabClass}
      onClick={() => {
        props.toggleSelection(props.title);
      }}
    >
      <div>
        <p className="videoTitle">{props.title}</p>
        <p className="videoPrice">$99</p>
      </div>
      <div class="radio-container">
        <input
          // onClick={}
          checked={props.isSelected === props.title}
          type="radio"
          name="example"
        />
      </div>
    </div>
  );
};

export default VideoTab;
