import React from "react";
import "./FAQ.css";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Text } from "@chakra-ui/react";

class FAQSection extends React.Component {
  toggleAnswer = (element) => {
    const answer = element.nextElementSibling;
    if (!answer) return; // Check if nextElementSibling is not null
    const isDisplayed = answer.style.display === "block";

    // Hide all answers
    document.querySelectorAll(".faq-answer").forEach((el) => {
      el.style.display = "none";
    });

    // Toggle the current answer
    if (!isDisplayed) {
      answer.style.display = "block";
    }
  };

  render() {
    return (
      <div className="faq-container">
        {/* <h4>FAQ</h4> */}
        <Text fontSize="24px" fontWeight="semibold" py={4}>
          FAQ
        </Text>
        {faqData.map((item, index) => (
          <Box className="faq-item" key={index} py={1} paddingTop={3} >
            <Box 
               mx={{base:2,md:2}}
              className="faq-question"
              onClick={(e) => this.toggleAnswer(e.currentTarget)}
            >
              {item.question} <ChevronDownIcon boxSize={6} />
            </Box>
            <Box
              mx={{base:0,md:2}}
              className="faq-answer"
              dangerouslySetInnerHTML={{ __html: item.answer }}
            />
          </Box>
        ))}
      </div>
    );
  }
}

const faqData = [
  {
    question: "What is House Of Content?",
    answer:
      "House Of Content is a self-serve content platform. Most customers love using HOC to create UGC videos for their online stores, organic social media content, and/or paid ads. With our diverse and hand-selected creator network, HOC allows companies to get the content they need to relate to their audiences and drive sales.",
  },
  {
    question: "What kind of content can be produced on HOC?",
    answer:
      "UGC Ads, testimonial videos, unboxing, product in use, product videography, explanation videos. You name it, HOC is built for custom content at your request.",
  },
  {
    question: "What is included in your services?",
    answer:
      "From scripting to high-quality editing, everything is included. You just need to ship your products; the rest we will take care of. :)",
  },
  {
    question: "How do I get started?",
    answer: `We are genuinely excited about what we’ve built and can’t wait to work with you. Click on order now and book a quick call with us.<br/>
            Below is the sequence of events:<br/>
            1. Quick call to understand your brand and requirements.<br/>
            2. Once we kick-start the project with script approval, ship your products to us.<br/>
            3. Review & approve content creators.<br/>
            4. Review & approve final videos with change requests in editing, if required.<br/>
            5. Get the video content delivered on the decided timeline, to use where you need it with complete licensing rights.`,
  },
  {
    question: "How long does it take to start my project?",
    answer:
      "We strive to work on your project once it’s finalized and we have all required details.",
  },
  {
    question: "How does pricing work?",
    answer:
      "We have 3 packages; you can select the best suitable for your requirements, and you can request a custom package for bulk videos.",
  },
  {
    question: "Who creates the content?",
    answer:
      "We have personally built a network of content creators. Every type of person: race, gender, shape, size, age, interests, etc., can be found in our network. These people are passionate content creators, actors, and models.",
  },
  {
    question: "Where can I use this content?",
    answer:
      "Every piece of content you approve is fully licensed to you. So, go nuts! Use it on your website, social media (IG, YouTube), Amazon product pages, IG shops, Google Shopping, ads, wherever you wish. :)",
  },
  {
    question: "How much control do I have over the content produced?",
    answer:
      "The brands that have the most success on HOC are collaborative in philosophy. You’ll be able to choose the category of content you need and outline key elements you need to see executed. It’s important to remind yourself of the reasons you are drawn to us for your content. Be sure your ideas are infused into their creative process without taking over the show, so to speak. This mindset will generate the most authentic and impactful photos and videos by a long shot. Collaborate and have fun!",
  },
  {
    question: "I have more questions. How can I talk with someone at HOC?",
    answer:
      "You can connect with us on a call by clicking on order now, or you can even email us at dhanraj@houseofcontent.club.",
  },
  {
    question: "How long does it take to get content?",
    answer:
      "That depends on which package you go with. However, on average, it takes 2-3 weeks to get all of your content produced.",
  },
];

export default FAQSection;
