// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  /* margin: 16px  100.5px ; */
}

.container-theme{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.theme-logo{
  width: 51.01px;
    height: 19.73px;
    flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,cAAc;IACZ,eAAe;IACf,cAAc;AAClB","sourcesContent":[".App {\n  /* margin: 16px  100.5px ; */\n}\n\n.container-theme{\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.theme-logo{\n  width: 51.01px;\n    height: 19.73px;\n    flex-shrink: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
