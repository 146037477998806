import React from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import Navigation from './Navigation/Navigation';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "GT-Walsheim-Pro-Regular, sans-serif",
      },
    },
  },
  fonts: {
    heading: "GT-Walsheim-Pro-Regular, sans-serif",
    body: "GT-Walsheim-Pro-Regular, sans-serif",
  },
});


function App(customTheme) {
  return (
    <ChakraProvider>
    <div className="App">
      <Navigation />
    </div>
    </ChakraProvider>
  );
}

export default App;
