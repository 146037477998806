import LandingPage from '../pages/LandingPage/LandingPage';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Login } from '../pages/Login/Login';
import Campaigns from '../pages/Campaigns/Campaigns';
import Pay from '../pages/Pay/Pay';
import Header from '../components/Header/Header';
import { Box } from '@chakra-ui/react';

const Navigation = () => {
  return (
    <Router>
    <Box position="fixed" top="0" width="100%"  boxShadow="sm" zIndex={9}>
        <Header  />
    </Box>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Campaigns" element={<Campaigns />} />
        <Route path="/Pay" element={<Pay />} />
      </Routes>
    </Router>
  );
};

export default Navigation;
