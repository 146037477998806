import React, { useState } from 'react';
import './Campaigns.css';
import Header from '../../components/Header/Header';
import VideoTab from '../../components/VideoTab';
import cScreen from '../../assets/cScreen.png';
import inhouse from '../../assets/inhouse.png';
import DurationTab from '../../components/DurationTab';
import { useNavigate } from 'react-router-dom';

const Campaigns = () => {
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState('Video ad');
  const [creator, setCreator] = useState(1);

  const toggleSelection = (data) => {
    setIsSelected(data);
  };
  return (
    <div>
      <Header />
      <div className="cHeader">
        <div className="cVideoTypes">
          <p className="cTitle">Video type</p>
          <div className="videoTabDiv">
            <VideoTab
              isSelected={isSelected}
              toggleSelection={toggleSelection}
              index="1"
              title="Video ad"
            />
            <VideoTab
              isSelected={isSelected}
              toggleSelection={toggleSelection}
              title="TikTok / Short"
            />
            <VideoTab
              isSelected={isSelected}
              toggleSelection={toggleSelection}
              title="Product showcase"
            />
          </div>
          <img
            style={{ marginTop: '10px' }}
            src={cScreen}
            width="100%"
            alt=""
          />
        </div>

        <div className="cNumberOfVideos">
          <p className="cNumberTitle">Number of videos / creators</p>
          <p className="cNumberSubTitle">
            You must giveaway your product to each selected creator.
          </p>
          <div className="group">
            <div
              onClick={() => {
                setCreator(creator - 1);
              }}
              className="numberCalculationDiv"
            >
              -
            </div>
            <p className="numberCreators">{creator} creators</p>
            <div
              onClick={() => {
                setCreator(creator + 1);
              }}
              className="numberCalculationDiv"
            >
              +
            </div>
          </div>
        </div>
        <div className="cNumberOfVideos">
          <p className="cNumberTitle">Video duration</p>
          <div className="videoTabDiv">
            <DurationTab
              isSelected={isSelected}
              toggleSelection={toggleSelection}
              index="1"
              title="30 Sec"
            />
            <DurationTab
              isSelected={isSelected}
              toggleSelection={toggleSelection}
              title="40 Sec"
            />
            <DurationTab
              isSelected={isSelected}
              toggleSelection={toggleSelection}
              title="50 Sec"
            />
          </div>
        </div>
        <div className="houseService">
          <p className="cNumberTitle">in-house Service </p>
          <img src={inhouse} width="350px" height="100%" alt="" />
        </div>
        <div className="cFooter">
          <p className="finalTitle">
            2 videos x $229 <br />
            <span
              style={{ color: 'black', marginRight: '10px', marginTop: '10px' }}
            >
              Total:
            </span>
            $458
          </p>
          <div className="borderWidth"></div>
          <button
            onClick={() => {
              navigate('/Pay');
            }}
            className="buttonOrderNow"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
