import React from 'react';
import Header from '../../components/Header/Header';
import './Pay.css';
import faq from '../../assets/faq.png';
import payment from '../../assets/payment.png';
import summary from '../../assets/summaryNew.png';
const Pay = () => {
  return (
    <div>
      <Header />
      <div className="cHeader">
        <div className="cVideoTypes">
          <img
            style={{ marginTop: '10px' }}
            src={summary}
            width="800px"
            alt=""
          />
        </div>
        <div className="cVideoTypes">
          <img style={{ marginTop: '10px' }} src={faq} width="100%" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Pay;
